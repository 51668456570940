// library imports
import styled, { withTheme } from "styled-components";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Intl from "src/components/common/Intl";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//image imports
import TurnoverIcon from "src/components/icon/TurnoverIcon";
import PromotionBg from "../../../images/promotion-amount-bg.png";
import PromotionApproved from "../../../images/approved-icon.png";
import ExpiredIcon from "../../../images/expired-icon.png";
import InProcessIcon from "../../../images/INPROCESS.png";

//config imports
import config from "src/config/config";
import { Link } from "react-router-dom";
import { withAppContext } from "src/store/initAppContext";
import { useEffect, useState } from "react";
import { theme } from "styled-tools";
import InformationIcon from "src/components/icon/InformationIcon";


interface BonusProps {
  promotionStatus: string;
  bonusAmount: number;
  rollOver: number;
  claimedAt: Date;
  expiredAt: Date;
  bonusTitle: string;
  turnover: number;
  percent: number;
  isCancel: boolean;
  refereeName: string;
  refferalDays: any;
  referralDaysPercent: any;
  dailyDepositBonus?: any;
  couponBonus?: any;
  topChoice?: boolean;
}

interface YourProps {
  released?: boolean;
  expired?: boolean;
  cancel?: boolean;
  percent?: number;
  progress?: boolean;
  disabled?: boolean;
}

const PromotionContainer = (props) => {
  const {
    promotionStatus,
    bonusAmount,
    percent,
    rollOver,
    claimedAt,
    expiredAt,
    bonusTitle,
    turnover,
    isCancel,
    refereeName,
    bonusId,
    promoType,
    refferalDays,
    referralDaysPercent,
    dailyDepositBonus,
    couponBonus,
    topChoice,
    theme,
  } = props;


  const [imageName, setImageName] = useState();

  useEffect(() => {
    switch (bonusId) {
      case 1:
        setImageName(theme.image.WelcomeBonusBg);
        break;
      case 2:
        setImageName(theme.image.ReferralBonusBg);
        break;
      case 4:
        setImageName(theme.image.CashbackBonusBg);
        break;
      case 3:
        setImageName(theme.image.RefillBonusBg);
        break;
      default:
        setImageName(theme.image.WelcomeBonusBg);
    }
  }, [bonusId]);


  console.log(couponBonus, "tttt")
  

   //days left to reffer
  const DaysLeft = refferalDays;
  const DaysLeftPercent = referralDaysPercent;

  return (
    <>
      <PromotionContainerWrap>
        <PromotionContainerLeftWrap>
          <PromotionContainerLeft>
            <PromotionContainerLeftImage
              src={`${config.imageV3Host}/static/cashsite/${imageName}`}
            />
            <PromotionContainerImageTitle>
               {promoType === "WcDailyCashback"? 'worldcup daily cashback bonus' : promoType === "IPLDailyCashback" ? "IPL DAILY CASHBACK BONUS" : bonusTitle}
            </PromotionContainerImageTitle>
          </PromotionContainerLeft>
        </PromotionContainerLeftWrap>

        {bonusId === 2 && (promotionStatus === "INITIATED" || promotionStatus === "QUEUED") ? (
          <PromotionContainerRight>
            <PromotionContainerRightTopPending>
              <h5>
                {" "}
                <Intl langKey="Promotions@RefereeHurry" />
              </h5>
            </PromotionContainerRightTopPending>

            <PromotionContainerRightBottom>
              <ReferralInitatedWrapper>
                <ReferralInitiatedLeft>
                  <h6>OFFER ENDS IN</h6>
                  <CircularProgressbar value={DaysLeftPercent} text={`${DaysLeft}`} />
                  <h6>DAYS</h6>
                </ReferralInitiatedLeft>
                <ReferralInitatedRight>
                  <h5>
                    {" "}
                    <Intl langKey="Promotions@ReferralFriend" />
                  </h5>
                  <h6>{refereeName}</h6>
                  <p>
                       {promoType === "FTD" ? <Intl langKey="Promotions@ReferralDesc" /> : promoType === "STD" ? <Intl langKey="Promotions@ReferralMessageTooltipSecond" /> : <Intl langKey="Promotions@ReferralMessageTooltipThird" /> }
                  </p>
                </ReferralInitatedRight>
              </ReferralInitatedWrapper>
            </PromotionContainerRightBottom>

            <PromotionContainerFooter>
              <Link to={`/promotions/${bonusTitle}`}>
                <TermsButton>
                  <Intl langKey="Promotions@Terms" />
                </TermsButton>
              </Link>
              {isCancel === true ? (
                <StatusButton cancel={true} disabled="true">
                  <Intl langKey="Promotions@CANCEL" />
                </StatusButton>
              ) : promotionStatus === "RELEASED" ||
                promotionStatus === "EXPIRED_PENDING" ? (
                <StatusButton released={true}>
                  <Intl langKey="Promotions@COMPLETED" />
                </StatusButton>
              ) : promotionStatus === "EXPIRED" ||
                promotionStatus === "RELEASED_EXPIRED" ? (
                <StatusButton expired={true}>
                  <Intl langKey="Promotions@EXPIRED" />
                </StatusButton>
              ) : (
                <></>
              )}
            </PromotionContainerFooter>
          </PromotionContainerRight>
        ) : (
          <PromotionContainerRight>
            {rollOver != 0 ? (
              <PromotionContainerRightTopPending>
                <StatusBadgePending>
                  {promotionStatus === "RELEASED" ||
                  promotionStatus === "EXPIRED_PENDING" ? (
                    <img src={PromotionApproved} alt="PromotionApproved" />
                  ) : promotionStatus === "EXPIRED" ||
                    promotionStatus === "RELEASED_EXPIRED" ? (
                    <img src={ExpiredIcon} alt="ExpiredIcon" />
                  ) : (
                    <img src={InProcessIcon} alt="InProcessIcon" />
                  )}
                </StatusBadgePending>
                <ProgressWrap>
                  <ProgressBarLimitsTop>
                    <p>
                      {" "}
                      <Intl langKey="Promotions@Amount" /> {bonusAmount}{" "}
                    </p>
                    <TurnoverSec>
                      <TurnoverIcon />
                      <span>
                        <Intl langKey="Promotions@Turnover" />
                      </span>
                    </TurnoverSec>
                  </ProgressBarLimitsTop>
                  <ProgressBarNewWrapper>
                    <ProgressMinAmount>0</ProgressMinAmount>
                    <ProgressBarMain>
                      <Progress
                        percent={percent}
                        theme={{
                          success: {
                            color: "#0EAD69",
                            // symbol: '100%'
                          },
                          active: {
                            color: "#0EAD69",
                          },
                          default: {
                            color: "#0EAD69",
                          },
                        }}
                      />
                    </ProgressBarMain>
                    <ProgressMaxAmount>
                      {Math.round(rollOver)}
                    </ProgressMaxAmount>
                  </ProgressBarNewWrapper>
                  <ProgressBarLimits>
                    <ProgressMinAmount></ProgressMinAmount>
                    <ProgressBarAmountIndicator>
                      <ProgressAmountInnerWrap percent={percent}>
                        <ProgressBarMaxRound
                          percent={percent}
                        ></ProgressBarMaxRound>
                        <ProgressBarAmountWrapper percent={percent}>
                          <img
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.DoneIcon}`}
                            alt=""
                          />
                          <p>
                            ₹
                            {turnover > rollOver
                              ? Math.round(rollOver)
                              : Math.round(turnover)}
                            /-{" "}
                          </p>
                        </ProgressBarAmountWrapper>
                      </ProgressAmountInnerWrap>
                    </ProgressBarAmountIndicator>
                    <ProgressMaxAmount></ProgressMaxAmount>
                  </ProgressBarLimits>
                </ProgressWrap>
              </PromotionContainerRightTopPending>
            ) : (
              <PromotionContainerRightTop>
                    <AmountBadge>
                      <div>₹{bonusAmount}/-</div>
                      {
                          bonusId === 4 && dailyDepositBonus > 0 ? <DailyDepositMessage><InformationIcon /> <p><Intl langKey="Promotions@DailyDepositMsg" /> ₹{Number(dailyDepositBonus).toFixed(2)} <Intl langKey="Promotions@DailyDepositMsg2" /></p></DailyDepositMessage>
                          : !topChoice && couponBonus > 0 ? <DailyDepositMessage><InformationIcon /> <p><Intl langKey="Promotions@IplDailyCashbackMsg" /> ₹{Number(couponBonus).toFixed(2)} <Intl langKey="Promotions@IplDailyCashbackMsg2" /></p></DailyDepositMessage>
                          : topChoice && couponBonus > 0 ? <DailyDepositMessage><InformationIcon /> <p><Intl langKey="Promotions@TopChoiceCashbackMsg2" /> ₹{Number(couponBonus).toFixed(2)} <Intl langKey="Promotions@IplDailyCashbackMsg2" /></p></DailyDepositMessage>
                          : topChoice && (couponBonus <= 0 || couponBonus === null) ? <DailyDepositMessage><InformationIcon /> <p><Intl langKey="Promotions@TopChoiceCashbackMsg" /></p></DailyDepositMessage>
                          : <></>
                      }
                    </AmountBadge>
                <StatusBadge>
                  {promotionStatus === "RELEASED" ||
                  promotionStatus === "EXPIRED_PENDING" ? (
                    <img src={PromotionApproved} alt="Promotion Approved" />
                  ) : promotionStatus === "EXPIRED" ||
                    promotionStatus === "RELEASED_EXPIRED" ? (
                    <img src={ExpiredIcon} alt="Expired Icon" />
                  ) : (
                    <img src={InProcessIcon} alt="InProcess Icon" />
                  )}
                </StatusBadge>
              </PromotionContainerRightTop>
            )}
            {bonusId == 2 ? (
              <PromotionContainerRightBottom>

               {promoType === "TTD" ?
                <PromotionContainerRightBottomDetailsHeadWrap>
                     <ReferralNumberText><Intl langKey="Promotions@3rdReferral" /></ReferralNumberText> 
                </PromotionContainerRightBottomDetailsHeadWrap> : promoType === "STD" ?
                <PromotionContainerRightBottomDetailsHeadWrap>
                     <ReferralNumberText><Intl langKey="Promotions@2ndReferral"/></ReferralNumberText>  
                </PromotionContainerRightBottomDetailsHeadWrap> : 
                <PromotionContainerRightBottomDetailsHeadWrap>
                     <ReferralNumberText><Intl langKey="Promotions@1stReferral" /></ReferralNumberText>  
                </PromotionContainerRightBottomDetailsHeadWrap>
                }

                <PromotionContainerRightBottomDetailsHeadWrap>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@RefereeName" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Amount" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Reqdate" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Exdate" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@status" />
                  </PromotionContainerRightBottomDetailsHead>
                </PromotionContainerRightBottomDetailsHeadWrap>
                <PromotionContainerRightBottomDetailsInfoWrap>
                  <PromotionContainerRightBottomDetaisInfo>
                    {refereeName}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {bonusAmount}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {claimedAt}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {expiredAt}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {promotionStatus === "RELEASED" ||
                    promotionStatus === "EXPIRED_PENDING" ? (
                      <StatusText released={true}>
                        {" "}
                        <Intl langKey="Promotions@RELEASED" />
                      </StatusText>
                    ) : promotionStatus === "EXPIRED" ||
                      promotionStatus === "RELEASED_EXPIRED" ? (
                      <StatusText expired={true}>
                        <Intl langKey="Promotions@EXPIRED" />
                      </StatusText>
                    ) : (
                      <StatusText>
                        <Intl langKey="Promotions@PROCESS" />
                      </StatusText>
                    )}
                  </PromotionContainerRightBottomDetaisInfo>
                </PromotionContainerRightBottomDetailsInfoWrap>
              </PromotionContainerRightBottom>
            ) : (
              <PromotionContainerRightBottom>
                <PromotionContainerRightBottomDetailsHeadWrap>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Amount" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Reqdate" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@Exdate" />
                  </PromotionContainerRightBottomDetailsHead>
                  <PromotionContainerRightBottomDetailsHead>
                    <Intl langKey="Promotions@status" />
                  </PromotionContainerRightBottomDetailsHead>
                </PromotionContainerRightBottomDetailsHeadWrap>
                <PromotionContainerRightBottomDetailsInfoWrap>
                  <PromotionContainerRightBottomDetaisInfo>
                    {bonusAmount}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {claimedAt}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {expiredAt}
                  </PromotionContainerRightBottomDetaisInfo>
                  <PromotionContainerRightBottomDetaisInfo>
                    {promotionStatus === "RELEASED" ||
                    promotionStatus === "EXPIRED_PENDING" ? (
                      <StatusText released={true}>
                        {" "}
                        <Intl langKey="Promotions@RELEASED" />
                      </StatusText>
                    ) : promotionStatus === "EXPIRED" ||
                      promotionStatus === "RELEASED_EXPIRED" ? (
                      <StatusText expired={true}>
                        <Intl langKey="Promotions@EXPIRED" />
                      </StatusText>
                    ) : (
                      <StatusText>
                        <Intl langKey="Promotions@PROCESS" />
                      </StatusText>
                    )}
                  </PromotionContainerRightBottomDetaisInfo>
                </PromotionContainerRightBottomDetailsInfoWrap>
              </PromotionContainerRightBottom>
            )}
            {bonusId === 2 && promotionStatus === "INITIATED" ? (
              <PromotionContainerFooter>
                <Link to={`/promotions/${bonusTitle}`}>
                  <TermsButton>
                    <Intl langKey="Promotions@Terms" />
                  </TermsButton>
                </Link>
                <StatusButton progress={true}>
                  <Intl langKey="Promotions@PROCESS" />
                </StatusButton>
              </PromotionContainerFooter>
            ) : (
              <PromotionContainerFooter>
                <Link to={`/promotions/${bonusTitle}`}>
                  <TermsButton>
                    <Intl langKey="Promotions@Terms" />
                  </TermsButton>
                </Link>
                {isCancel === true ? (
                  <StatusButton cancel={true} disabled="true">
                    <Intl langKey="Promotions@CANCEL" />
                  </StatusButton>
                ) : promotionStatus === "RELEASED" ||
                  promotionStatus === "EXPIRED_PENDING" ? (
                  <StatusButton released={true}>
                    <Intl langKey="Promotions@COMPLETED" />
                  </StatusButton>
                ) : promotionStatus === "EXPIRED" ||
                  promotionStatus === "RELEASED_EXPIRED" ? (
                  <StatusButton expired={true}>
                    <Intl langKey="Promotions@EXPIRED" />
                  </StatusButton>
                ) : (
                  <></>
                )}
              </PromotionContainerFooter>
            )}
          </PromotionContainerRight>
        )}
      </PromotionContainerWrap>
    </>
  );
};

export default withTheme(withAppContext(PromotionContainer));

const PromotionContainerWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px dashed #ffffff;
  position: relative;

  @media (min-width: 1950px) {
    max-width: 1400px;
  }
`;

const PromotionContainerLeftWrap = styled.div`
  position: absolute;
  left: 0px;
  z-index: 999;
  width: 350px;
  height: 100%;

  @media (max-width: 1140px) {
    width: 40%;
  }

  @media (min-width: 1600px) {
    width: 50%;
  }
`;

const PromotionContainerLeft = styled.div`
  -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
  position: relative;
  width: 100%;
  margin-left: -1%;
  height: 100%
`;

const PromotionContainerLeftImage = styled.img`
  border-radius: 0px !important;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const PromotionContainerRight = styled.div<Pick<YourProps, "disabled">>`
  background-color: ${(p) => (p.disabled ? "rgba(70, 77, 85, 0.96);" : "#ffffff")}; 
  flex: 1;
  height: 100%;
  padding: 10px 15px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: ${(p) => (p.disabled ? "relative" : "static")}; 

  @media (min-width: 1600px) {
    padding: 17px 15px 0px 0px;
  }
`;
const ProgressWrap = styled.div`
  display: block;
  width: 80%;
`;

const PromotionContainerImageTitle = styled.h6`
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 12%;
  right: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0px;
`;

const PromotionContainerRightDisabledMessage = styled.p`
  position: absolute;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin: 0px;
  top: 65px;
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  & svg {
    cursor: pointer;
  }
`
const PromotionContainerRightTopPending = styled.div<Pick<YourProps, "disabled">>`
  background: ${(p) => (p.disabled ? "rgba(70, 77, 85, 0.96);" : "linear-gradient(180deg, #000000 -15.93%, #383838 100%);")}; 
  border-radius: 0px 5px 0px 0px;
  padding: 5px 0px;
  height: 69px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  padding-left: 20em;
  width: 100%;

  @media (max-width: 1140px) {
    padding-left: 15em;
  }
  @media (min-width: 1600px) {
    padding-left: 28em;
  }
  @media (min-width: 1700px) {
    padding-left: 32em;
  }

  .react-sweet-progress-symbol {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35px;
    height: 20px;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 200;
    display: none;
  }

  & h5 {
    font-size: 25px;
    color: white;
    font-weight: bold;
    margin: 15px 0px;
  }
`;

const PromotionContainerRightTop = styled.div`
  background: linear-gradient(180deg, #000000 -15.93%, #383838 100%);
  border-radius: 0px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 17.2px 20px;
  width: 100%;
  padding-left: 20em;

  @media (max-width: 1140px) {
    padding-left: 15em;
  }

  @media (min-width: 1600px) {
    padding-left: 28em;
  }
  @media (min-width: 1700px) {
    padding-left: 32em;
  }
`;

const AmountBadge = styled.div`
  background-image: url(${PromotionBg});
  font-weight: 900;
  font-size: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  text-align: center;
  border-radius: 5px;
  padding: 6px 20px;
  border: 1px solid linear-gradient(92.89deg, #e8e489 0%, #cb9640 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px ;
`;

const StatusBadge = styled.div`
  & svg {
    position: absolute;
    bottom: -30px;
    width: 85px;
    height: 115px;
    transform: rotate(-22.25deg);
  }

  & img {
    position: absolute;
    bottom: 1px;
    width: 69px;
    height: 62px;
    object-fit: contain;
    transform: rotate(-22.25deg);
  }
`;

const StatusBadgePending = styled.div`
  & svg {
    width: 62px;
    transform: rotate(-22.25deg);
  }

  & img {
    width: 66px;
    object-fit: contain;
    transform: rotate(-22.25deg);
    margin-left: -20px;
  }
`;

const PromotionContainerRightBottom = styled.div<Pick<YourProps, "disabled">>`
  background: ${(p) => (p.disabled ? "rgba(70, 77, 85, 0.96);" : "rgba(255, 255, 255, 0.9)")}; 
  opacity: ${(p) => (p.disabled ? "0" : "0.95")}; 
  box-shadow: 0px 0px 50px rgb(16 12 74 / 40%);
  padding: 10px 10px;
  width: 100%;
  padding-left: 20em;

  @media (max-width: 1140px) {
    padding-left: 15em;
  }

  @media (min-width: 1600px) {
    padding-left: 28em;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (min-width: 1700px) {
    padding-left: 32em;
  }
  @media (min-width: 1950px) {
    padding-left: 38em;
  }
`;

const ReferralInitatedWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const ReferralInitiatedLeft = styled.div`
  width: 27%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .CircularProgressbar {
    width: 40%;
    vertical-align: middle;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: #000;
    font-size: 30px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: bold;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #04ce00;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  & h6 {
    margin: 3px 0px;
    font-size: 15px;
    font-weight: 700;
    color: #6778e3;
    text-align:center;
  }
`;
const ReferralInitatedRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 73%;

  & h5 {
    margin: 3px 0px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  & h6 {
    margin: 3px 0px;
    font-size: 20px;
    font-weight: 600;
    color: #53537b;
  }
  & p {
    margin: 3px 0px;
    font-size: 17px;
    font-weight: 400;
    color: #53537b;
  }
`;

const ReferralNumberText = styled.p`
  margin: 5px 20px;
  font-size: 12px;
  color: #505050;
  letter-spacing: 0.03em;
  font-weight: 600;
`

const PromotionContainerRightBottomDetailsHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.5px dashed #000000;
`;


const PromotionContainerRightBottomDetailsHead = styled.h6`
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #505050;
  margin: 3px 0px;
  width: 24%;
  text-align: center;

  @media (max-width: 1100px) {
    width: 25%;
    padding: 0px !important;
  }
`;

const PromotionContainerRightBottomDetailsInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 3px;
`;

const PromotionContainerRightBottomDetaisInfo = styled.p`
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #53537b;
  width: 24%;
  margin: 0px;

  @media (max-width: 1100px) {
    width: 25%;
    padding: 0px !important;
  }
`;
const ProgressBarMain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const ProgressBarNewWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const ProgressBarLimitsTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  & p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #ffffff;
    margin: 3px 0px;
  }
`;
const ProgressMinAmount = styled.p`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0px 5px;
`;
const ProgressMaxAmount = styled.p`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0px 5px;
  max-width: 60px;
  width: auto;
  min-width: 60px;
  text-align:center;
`;
const ProgressBarLimits = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 3px;
  width: 82%;
  position: relative;

  & p {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #ffffff;
    margin: 0px -5px;
  }
`;

const ProgressBarAmountIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  width: 86%;
`;
export const ProgressAmountInnerWrap = styled.div<Pick<YourProps, "percent">>`
  position: absolute;
  left: ${(p) =>
    p.percent === 100
      ? `${p.percent - 2}%`
      : p.percent === 0
      ? `${p.percent + 2}%`
      : p.percent >= 70
      ? `${p.percent - 7}%`
      : p.percent > 0
      ? `${p.percent}%`
      : p.percent < 1
      ? "10px"
      : "0px"};
  top: -14px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1400px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent - 6}%`
        : p.percent >= 90
        ? `${p.percent - 9}%`
        : p.percent >= 70
        ? `${p.percent - 7}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent >= 60
        ? `${p.percent}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1450px) {
    left: ${(p) =>
        p.percent >= 90
        ? `${p.percent }%`
        : p.percent >= 70
        ? `${p.percent - 3}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent === 100
        ? `${p.percent}%`
        : p.percent >= 60
        ? `${p.percent}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1750px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent}%`
        : p.percent >= 90
        ? `${p.percent}%`
        : p.percent >= 70
        ? `${p.percent - 3}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent >= 60
        ? `${p.percent}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1900px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent + 3}%`
        : p.percent >= 90
        ? `${p.percent}%`
        : p.percent >= 70
        ? `${p.percent}%`
        : p.percent >= 50
        ? `${p.percent}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1900px) and (max-width: 1950px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent}%`
        : p.percent >= 90
        ? `${p.percent - 2}%`
        : p.percent >= 70
        ? `${p.percent}%`
        : p.percent >= 50
        ? `${p.percent}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent - 6}%`
        : p.percent >= 90
        ? `${p.percent - 10}%`
        : p.percent >= 60
        ? `${p.percent - 7}%`
        : p.percent >= 50
        ? `${p.percent - 2}%`
        : p.percent >= 70
        ? `${p.percent - 7}%`
        : p.percent >= 80
        ? `${p.percent - 13}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
        : "0px"};
  }
  @media screen and (min-width: 1300px) and (max-width: 1450px) {
    left: ${(p) =>
      p.percent === 100
        ? `${p.percent - 2}%`
        : p.percent >= 80
        ? `${p.percent - 4}%`
        : p.percent >= 70
        ? `${p.percent - 4}%`
        : p.percent >= 90
        ? `${p.percent - 9}%`
        : p.percent >= 50
        ? `${p.percent - 2}%`
        : p.percent >= 60
        ? `${p.percent - 2}%`
        : p.percent <= 10
        ? `${p.percent + 2}%`
        : p.percent === 0
        ? `${p.percent + 2}%`
        : p.percent < 1
        ? "10px"
        : p.percent > 0
        ? `${p.percent}%`
    
        : "0px"};
  }
`;

const ReferralCodeSec = styled.div`
  display: flex;
  width: 100%;
  padding-left: 5em;
  align-items: center;
  height: 40px;

  @media (max-width: 1140px) {
    padding-left: 5em;
  }
  @media (max-width: 1430px) {
    padding-left: 2em;
  }

  @media (min-width: 1600px) {
    padding-left: 4em;
    padding-top: 15px;
  }
  @media (min-width: 1700px) {
    padding-left: 4em;
  }
  @media (min-width: 1950px) {
    padding-left: 5em;
    height: 40px;
  }

  & h6 {
    font-size: 15px;
    font-weight: 600;
    margin: 3px 0px;

    @media (max-width: 1430px) {
      font-size: 12px;
    }
  }
  & p {
    font-size: 14px;
    font-weight: 500;
    margin: 3px 5px;

    @media (max-width: 1430px) {
      font-size: 11px;
    }
  }
  & img {
    cursor: pointer;
  }
`;

export const ProgressBarAmountWrapper = styled.div<Pick<YourProps, "percent">>`
  background-image: url(${config.imageV3Host}/static/cashsite/${theme("image.promotionAmountBg")});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 65px;
  width: auto;
  max-width: 130px;
  text-align: center;
  max-width: auto;
  padding: 6px 4px 0px 6px;
  display: ${(p) => (p.percent > 0 ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  position: absolute;
  left: -27px;
  top: 15px;

  & p {
    font-size: 10px;
    color: black;
    margin: 3px 0px;
  }

  & img {
    width: 13px;
    height: 12px;
    object-fit: contain;
  }
`;
export const ProgressBarMaxRound = styled.div<Pick<YourProps, "percent">>`
  border: 2px solid white;
  border-radius: 10px;
  width: 15px;
  height: 15px;
  background-color: #0ead69;
  display: ${(p) => (p.percent > 0 ? "block" : "none")};
`;

const TurnoverSec = styled.div`
  display: flex;
  align-items: center;

  & span {
    margin: 3px 0px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #ffdda6;
  }
  & svg {
    width: 12px;
    height: 12px;
  }
  & p {
    margin: 3px 0px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #fff;
  }
`;

const PromotionContainerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 35px;
  padding-left: 23em;
`;

const TermsButton = styled.button<Pick<YourProps, "disabled">>`
  background: "#000000";
  opacity:${(p) => (p.disabled  ? "0" : "0.32")};
  border-radius: 3px;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  border: none;
`;

const StatusButton: any = styled.button<
  Pick<YourProps, "expired" | "released" | "cancel" | "progress">
>`
  background: ${(p) =>
    p.expired
      ? "#FF1B20"
      : p.released
      ? "#0EAD69"
      : p.cancel
      ? "#AC001D"
      : p.progress 
      ? "#FF7A00"
      : "lightgray"};
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 5px 10px;
  margin-left: 3%;
  cursor: pointer;
  border: none;
  outline: none;
`;

const StatusText: any = styled.p<
  Pick<YourProps, "expired" | "released" | "cancel">
>`
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${(p) => (p.expired ? "#FF1B20" : p.released ? "#214076" : "#0EAD69")};
  margin: 0px;
`;

const ToolTipSec = styled.div`
    width: auto;
    position: relative;
    transform: 700ms;
    height: auto;
    min-height: 22px;

    & h6 {
            font-size: 12px;
            margin: 3px 0px;
            color: white;
            width: 200px;
            height: auto;
            padding: 10px;
            background-color: #8f8f8ff0;
            display: none;
            position: absolute;
            right: 0px;
            top: 20px
    }

    :hover{
             h6 {
                display: block;
            }
        }
    & svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
`

const DailyDepositMessage = styled.div`
  width: auto;
  height: 15px;

  & p {
    font-size: 12.5px;
    color: #ffffff;
    display: none;
    position: absolute;
    margin: 3px 0px;
    top: 48px;
    background-color: darkgray;
    padding: 10px;
    z-index: 9999;
    right: 18%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  & svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  :hover{
          p {
            display: block;
        }
    }
`
