import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import Footer from 'src/components/footer/footer';

function FomoWorldCupGoIndiaGoJackpot(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 39 ? "FOMO7" : 'Baazi Adda';


    return (
        <>
            <InformationWrapper>
                <HeadWrapper>
                    <p>This June, be a part of the biggest Jackpot by supporting your home team!</p>
                    <p>We present ‘Go India Go’ Jackpot on all cricket bets!</p>
                </HeadWrapper>
                <Divider></Divider>
                <LoyaltyWrapper>
                    <HeadingText className='titleText'>
                        <h1>Be a part of the Biggest Prizes!</h1>
                    </HeadingText>
                    <UnderLine></UnderLine>
                </LoyaltyWrapper>
                <JoiningWrapper>
                    <JoiningContainer>
                        <JoiningContent>
                            <RightContent>
                                <h2>Deposit</h2>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcGoIndiaGoJaskpotImagePrizeOne}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </RightContent>
                            <UnderLine></UnderLine>
                            <p>Deposit in your {domain} account. Keep it loaded and ready for action throughout the  T20 World Cup.</p>
                        </JoiningContent>
                        <JoiningContent>
                            <RightContent>
                                <h2>Bet & Win</h2>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcGoIndiaGoJaskpotImagePrizeTwo}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </RightContent>
                            <UnderLine></UnderLine>
                            <p>Place your bets on our platform. Higher the bet amount, higher the chances of winning!</p>
                        </JoiningContent>
                        <JoiningContent>
                            <RightContent>
                                <h2>Claim Your Prize</h2>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcGoIndiaGoJaskpotImagePrizeThree}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                            </RightContent>
                            <UnderLine></UnderLine>
                            <p>Claim Jackpot prizes based on bet amount throughout the tournament!</p>
                        </JoiningContent>
                    </JoiningContainer>
                </JoiningWrapper>
                <TableWrapper>
                    <HeadingText className='titleText'>
                        <h1>Jackpot Scorecard for 2024</h1>
                    </HeadingText>
                    <UnderLine></UnderLine>
                    <p>Validity: This scheme is valid from 2nd June to 29th June.</p>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcGoIndiaGoJaskpotTable}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </TableWrapper>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h1>Terms & Conditions</h1>
                    </TermsandconditionsHeading>
                    <UnderLine></UnderLine>
                    <TermsandconditionsBody>
                        <ol>
                            <li>This promotion is valid for all {domain} members.</li>
                            <li>The winners will be chosen on highest bet amount!</li>
                            <li>Bonus amount will be issued for all winners!</li>
                            <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. {domain} reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.</li>
                            <li>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</li>
                            <li>{domain} reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</li>
                            <li>In the event, any fraudulent activity of the player(s) has been identified, {domain} reserves the right to withhold your funds and reject your withdrawals.</li>
                            <li>Winners will be announced a week after the Word cup ends.</li>
                            <li>{domain} General Terms & Conditions apply</li>
                        </ol>
                    </TermsandconditionsBody>
                </Termsandconditions>
            </InformationWrapper>
            <Footer />
        </>
    )
}



export default withTheme(withAppContext(FomoWorldCupGoIndiaGoJackpot));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 0 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcGoIndiaGoJaskpotBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const HeadWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem 3rem 8rem;
    gap: 1rem;

    p {
        margin: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 1.2rem 0;
        h1 {
            margin: 0;
        }
    }
`;

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    margin: 0 -3rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcGoIndiaGoJaskpotTableBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    .titleText {
        padding: 1rem 0 1.2rem 0;
        h1 {
            margin: 0;
        }
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    img {
        padding-top: 3rem;
        width: 50%;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: -999;
    margin: 0 -3rem;
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    padding: 2rem 0 3rem 0;
`;

const JoiningContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 1.5rem 2rem 1.5rem;
    width: 25%;
    border-radius: 10px;
    border: 0.8px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(136.33% 139.78% at 0% 0%, rgba(254, 186, 59, 0.00) 63.5%, rgba(254, 186, 59, 0.20) 100%), radial-gradient(136.33% 139.78% at 0% 0%, rgba(255, 95, 31, 0.20) 0%, rgba(255, 95, 31, 0.00) 53.5%), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
    position: relative;

    p {
        margin-bottom: 0;
        color: #FFF;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%; /* 23.2px */
    }
`;

const RightContent = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-top: 1rem;
    width: 100%;

    img {
        width: 10rem;
        position: absolute;
        right: 2%;
        bottom: 50%;
    }

    h2 {
        color: #FEBA3B;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 29px */
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 1rem;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 75%;
    height: auto;
    margin: 1rem 12.5% 3rem 12.5%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcGoIndiaGoJaskpotTcAfter")});
        width: 6rem;
        height: 6rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -5%;
        bottom: 13%;
        z-index: -1;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcGoIndiaGoJaskpotTcBefore")});
        width: 9.39rem; /* 150.243px */
        height: 23.5rem; /* 376px */
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -10%;
        bottom: 35%;
        z-index: -1;
    }
`;