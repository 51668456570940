import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'

type Props = {app:any}

const TVbetSEO = (props: Props) => {
const {app: {brandId}}=props;

const domainName = brandId === 31 || brandId === 32 ? 'YOLO247' : brandId === 33 ? 'Baazi Adda' : brandId === 39 ? 'FOMO7':  'Betdaily' ;


    return (
        <>
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label"><h1>TVBET</h1></label>
                    <div className="accordion__content">

                        <h2>Why Choose {domainName} TVBET Casino Games?</h2>
                        <p>TVBET casino games offer an enthralling online casino gaming experience. TVBET was founded in 2016 by CEO Peter Korpusenko, who brought together iGaming industry experts. The goal was to create a product that would be a perfect mix of online casino games and sports betting.

                            The company has made rapid strides and has expanded into five regions, including Asia. TVBET thrives on technological superiority and fascinating game mechanics.
                        </p>
                        <h2>Popular TVBET Casino Games You Can Play on {domainName}</h2>
                        <p>The following is a list of {domainName} TVBET casino games:</p>
                        <ul>
                            <li>1Bet</li>
                            <li>Keno</li>
                            <li>7Bet</li>
                            <li>5Bet</li>
                            <li>Poker</li>
                            <li>Wheel Bet</li>
                            <li>War of Elements</li>
                            <li>Lucky 6</li>
                        </ul>

                        <h3>Overview of 1Bet:</h3>
                        <p>1Bet, or Bingo37, is a fast-paced and straightforward live game where 1 out of the thirty-seven numbered balls is drawn.</p>
                        <ul>
                            <li>Players can bet on Numbers - intervals or exact numbers, over/under, even/odd.</li>
                            <li>Bet history is available where players can check their previous placed bets.</li>
                            <li>Results option allows players to check the game previous game results.</li>
                            <li>Check stats, last wins, and last jackpots of the game.</li>
                        </ul>

                        <h3>Overview of Keno:</h3>
                        <p>In the Keno game, 20 out of 80 numbers are selected. Predict the winning numbers drawn.</p>
                        <ul>
                            <li>Keno game stats are available where players can check recent draws, most and least common numbers in consecutive draws, most common numbers, least common numbers, and number statistics.</li>
                            <li>Number of events available to choose from and place a bet.</li>
                            <li>Check out the odds from the table.</li>
                            <li>Get the last wins and last jackpots.</li>
                        </ul>

                        <h3>Overview of 7Bet:</h3>
                        <p>7Bet is a famous and quick live game in which seven out of forty-two numbered balls are picked randomly.</p>
                        <ul>
                            <li>Bet History, Last Wins, and Last Jackpots are available in the game.</li>
                            <li>Betting Options - Colors - Blue/Black, Numbers to be (or not) selected, Even/Odd, Over/Under</li>
                            <li>Players can check their potential winnings before placing bets.</li>
                        </ul>

                        <h3>Overview of 5Bet:</h3>
                        <p>5Bet is a famous game where the lottery machine randomly picks five out of the thirty-six balls.</p>
                        <ul>
                            <li>The bet types are Blue or Black colors, numbers, even/odd, over/under, etc.</li>
                            <li>Players can go through the bet history, last wins, and last jackpots, and make informed decisions about their bets.</li>
                        </ul>

                        <h3>Overview of Poker:</h3>
                        <p>PokerBet has five rounds, just like the regular Texas Hold'em. During these rounds, bets are accepted on combinations and positions of the hand. It is a live game that takes place without any interruption. Rules are the same as in Texas Hold'em Poker.</p>
                        <ul>
                            <li>Four rounds are there - Preflop, Flop, Turn, and River.</li>
                            <li>Players have the option of selecting an event to place bets.</li>
                            <li>Bet history, last wins, and last jackpots features are available in the game.</li>
                        </ul>

                        <h3>Overview of WheelBet:</h3>
                        <p>WheelBet is a fun and exciting game combining American Roulette's thrills and the wheel of fortune. The dealer starts the game by spinning the wheel slightly in the anticlockwise direction and then launches it clockwise into a free rotation. Once the wheel stops, the pointer indicating a sector with a particular color or number wins.</p>
                        <ul>
                            <li>Option of selecting an event to place bets is available.</li>
                            <li>Bet history, stats, last wins, and last jackpots available that help players make better betting decisions.</li>
                        </ul>

                        <h3>Overview of War of Elements:</h3>
                        <p>War of Elements is a fast and engaging game between a dealer and a player. The game is played in live mode and is available 24*7.</p>
                        <ul>
                            <li>Select an event to place a bet.</li>
                            <li>Players can go through the bet history, last wins, and last jackpots.</li>
                            <li>Players can choose the outcomes - Dealer/Player wins, war, card suits, colors, over/under, winning combinations.</li>
                        </ul>

                        <h3>Overview of Lucky 6:</h3>
                        <p>Lucky 6 is an engaging live game. In this game, thirty-five numbers are drawn randomly from the forty-eight balls in the machine. A player has to predict six numbers that will come out of the machine as early as possible. The main betting option in Lucky 6 is a bet on six numbers. Other betting options include betting on the ball's color, odd or even, and the totals. A player will receive higher winnings if the selected six numbers are drawn.</p>
                        <ul>
                            <li>Paytable and Odds are available to check before placing the bets.</li>
                            <li>Bet history, stats, last wins, and last jackpots players can check.</li>
                        </ul>

                        <ul>
                            <li>Array of Exciting Games</li>
                            <p>{domainName} TVBET casino games are exciting and fun to play. There are many games that you can play and bet on. Some of the most-loved games on TVBET include Andar Bahar, 1Bet, Lucky 6, Keno, 7Bet, 5Bet, Poker, Wheel Bet, and War of Elements.</p>
                            <li>Engaging Graphics</li>
                            <p>The games on {domainName} TVBET are visually appealing, keeping the players engaged for a long time. These games boast immersive graphics that enhance their beauty.</p>
                            <li>Built using Latest Technology</li>
                            <p>The games are crafted using advanced technology, ensuring they run smoothly and provide a seamless gaming experience. These modern-day games are a perfect mix of entertainment and technology.</p>
                        </ul>

                        <h2>How to Access TVBET Casino Games on {domainName}?</h2>
                        <ol>
                            <li>Visit the {domainName} website or app</li>
                            <li>Select Casino and select Live Casino Games</li>
                            <li>Under Providers on the left-hand side, choose TVBET</li>
                            <li>Pick one game that you love the most and start betting on it</li>
                        </ol>

                        <h2>Why Choose {domainName} TVBET Casino Games?</h2>
                        <p>There are many reasons to choose TVBET casino games. Some prominent ones are as below:</p>

                        <h3>Array of Exciting Games</h3>
                        <p>{domainName} TVBET casino games are exciting and fun to play. There are many games that you can play and bet on. Some of the most-loved games on TVBET include Andar Bahar, 1Bet, Lucky 6, Keno, 7Bet, 5Bet, Poker, Wheel Bet, and War of Elements.</p>

                        <h3>Engaging Graphics</h3>
                        <p>The games on {domainName} TVBET are visually appealing, keeping the players engaged for a long time. These games boast immersive graphics that enhance their beauty.</p>

                        <h3>Built using Latest Technology</h3>
                        <p>The games are crafted using advanced technology, ensuring they run smoothly and provide a seamless gaming experience. These modern-day games are a perfect mix of entertainment and technology.</p>

                        <h2>How to Access TVBET Casino Games on {domainName}?</h2>
                        <p>To access the TVBET games on {domainName}, you need to follow the simple and quick steps given below:</p>
                        <ol>
                            <li>Visit the {domainName} website or app</li>
                            <li>Select Casino and select Live Casino Games</li>
                            <li>Under Providers on the left-hand side, choose TVBET</li>
                            <li>Pick one game that you love the most and start betting on it</li>
                        </ol>

                        <h2>FAQs on TVBET Casino Games</h2>
                        <ul>
                            <li>Which games are offered by {domainName} TVBET?</li>
                            <p>{domainName} TVBET casino games include 1Bet, Keno, 7Bet, 5Bet, Poker, Wheel Bet, War of Elements, and Lucky 6.</p>
                            <li>Why play {domainName} TVBET games?</li>
                            <p>{domainName} TVBET games are a perfect mix of entertainment and technology. Also, the games are visually appealing and easy to play.</p>
                        </ul>
                    
                </div>

            </Accordion>
        </AccordionWrapper >

        </>
    )
}

export default withTheme(withAppContext(TVbetSEO)) ;