import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext'
import styled, { withTheme } from 'styled-components'
import { theme } from 'styled-tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import Footer from 'src/components/footer/footer';

const WorldCupDoubleCashBackBazzi = (props) => {
    const {
        app: { brandId },
        theme,
    } = props;

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const domain = brandId == 31 || brandId == 32 ? "Yolo247" : "BaaziAdda";

    return (
        <>
            <MainWrapper>
                <ContentWrapper>
                    <TeamsSection>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackTeamsImage}`} effect="none" placeholder={<PlaceholderImage />} />
                    </TeamsSection>
                    <HeadingText>
                        <h1>Everyday Deposit Bonus!</h1>
                    </HeadingText>
                    <TitleText>
                        <p>Boost your thrill of T20 World Cup betting with daily deposits! Seize a turbocharged weekly double cashback upto 10%, and elevate the excitement with <span className='boldRed'>{domain}</span>!</p>
                    </TitleText>
                    <TitleText>
                        <p>As cricket fever sweeps the nation with the T20 world cup, <span className='boldRed'>{domain}</span> has a sensational offer for you. Get ready to score big and boost your scorecard with an astounding cashback. You can bag up to an astonishing 10%  double cashback!</p>
                    </TitleText>
                    <CashBackWrapper>
                        <LeftImgContent>
                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackMobileImage}`} effect="none" placeholder={<PlaceholderImage />} />
                        </LeftImgContent>
                        <BorderLine></BorderLine>
                        <RightTextContent>
                            <BonusTitle>
                                <h1>Embark on a Cricketing adventure</h1>
                            </BonusTitle>
                            <BonusBody>
                                <BonusContent>
                                    <h1>1. Pad Up:</h1>
                                    <p>Just like your favourite batsman, mark your presence every day. Make a deposit throughout the week, from Monday to Sunday.</p>
                                </BonusContent>
                                <BonusContent>
                                    <h1>2. Maintain Batting Form:</h1>
                                    <p>Just as cricketers maintain their form throughout a tournament, keep up your deposit streak for all seven days. Consistency is your ticket to unlocking the fantastic additional cashback bonus.</p>
                                </BonusContent>
                                <BonusContent>
                                    <h1>3. Celebrate Like Kohli:</h1>
                                    <p>Once you successfully deposit every day of the week, you become eligible for the additional cashback bonus. Play, Earn & Celebrate! </p>
                                </BonusContent>
                            </BonusBody>
                        </RightTextContent>
                    </CashBackWrapper>
                    <WeeklyBonusWrapper>
                        <WeeklyBonusContent>
                            <h1>Free Hits</h1>
                            <p>At the end of each week, you can turn your losses into wins with our sensational cashback bonus. You can avail up to an incredible 10% cashback bonus on your net losses incurred throughout the week.</p>
                        </WeeklyBonusContent>
                        <WeeklyBonusContent>
                            <h1>Monday Blues</h1>
                            <p>Beat the Monday blues with a Six – the cashback bonus amount will be  credited to your account every Monday.</p>
                        </WeeklyBonusContent>
                    </WeeklyBonusWrapper>
                    <Divider></Divider>
                    <DailyBonusWrapper>
                        <DailyBonusTitle>
                            <h1>Daily Deposit Scorecard for T20 WC 2024</h1>
                        </DailyBonusTitle>
                        <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.WorldCupDoubleCashBackWeeklyBonusTable}`} effect="none" placeholder={<PlaceholderImage />} />
                    </DailyBonusWrapper>
                    <Divider></Divider>
                    <TermsAndCondition>
                    <h4>Terms & Conditions</h4>
                        <ul>
                            <li>This promotion is valid for all new & existing <span className='boldWhite'>{domain}</span> members.</li>
                            <li>This bonus amount will be capped at INR 7,000.</li>
                            <li>Bonus amount will be issued into your Main wallet as cashback bonus every Monday only if you have successfully deposited all 7 days in a week (Mon-Sun). <span className='boldWhite'>Valid From 2nd June to 29th June 2024</span>.</li>
                            <li>Multiple accounts will not be considered for this promotion. All players will be subject to duplicate checking. <span className='boldWhite'>{domain}</span> reserves the right to disqualify players if any collusion or any foul play takes place in the promotion.</li>
                            <li>This promotion cannot be used in conjunction with, or to contribute to the eligibility for, any other promotion.</li>
                            <li><span className='boldWhite'>{domain}</span> reserves the right to amend, cancel and reclaim or refuse any promotion at its own discretion.</li>
                            <li>In the event, any fraudulent activity of the player(s) has been identified, <span className='boldWhite'>{domain}</span> reserves the right to withhold your funds and reject your withdrawals.</li>
                            <li><span className='boldWhite'>{domain}</span> General Terms & Conditions apply.</li>
                        </ul>
                    </TermsAndCondition>
                </ContentWrapper>
            </MainWrapper>
            <Footer />
        </>
    )
}

export default withTheme(withAppContext(WorldCupDoubleCashBackBazzi));

//Styled Components

const MainWrapper = styled.div`
    width: 98%;
    height: auto;
    border: 1px solid white;
    border-top: 0;
    display: flex;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    -webkit-backdrop-filter: brightness(0.5);
    backdrop-filter: brightness(0.5);
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupDoubleCashBackBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;

    .lazy-load-image-background {
      display: flex !important;
      justify-content: center !important;
    }

    .secondTitle {
        padding-top: 2rem;
    }
`;

const TeamsSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0rem 15rem;
    margin: 3.5rem 0;
    gap: 20px;
    position: relative;

    img{
        width: 100%;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        margin-top: 0;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }
`

const TitleText = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73%;

    p {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    
    .boldRed {
        color: #F91212;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const CashBackWrapper = styled.div`
    display: flex;
    padding: 2rem 8rem;
    width: 100%;
    justify-content: stretch;
    align-items: center;
`;

const LeftImgContent = styled.div`
    display: flex;
    width: 40%;
    text-align: right;
    justify-content: center;
    align-items: flex-end;

    img {
        width: 55%;
        align-self: flex-end;
    }
`;

const BorderLine = styled.div`
    width: 2px;
    background: linear-gradient(transparent, #F91212, transparent);
    height: 70%;
    align-self: center;
`;

const RightTextContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    background: radial-gradient(circle at left, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 20%);
    padding: 1rem 1rem 1rem 4rem;
`;

const BonusTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;

    h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }
`;

const BonusBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const BonusContent = styled(BonusBody)`
    width: 90%;
    h1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 0;
        color: #F91212;
    }
    p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize; 
        padding-left: 1rem;
    }
`;

const WeeklyBonusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    width: 80%;
    padding: 3rem;
`;

const WeeklyBonusContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #597AA1;
    border-radius: 8px;
    width: 50%;
    padding: 1rem 2rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WorldCupDoubleCashBackWeeklyBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        color: #F91212;
    }

    p {
        color: #FFF;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
`;

const DailyBonusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3rem 2rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);

    img {
        width: 70%;
    }
`;

const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#F91212 0%, #00000000 50%);
`;


const DailyBonusTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    position: relative;

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcDoublecashBackFireBall")});
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        right: 0%;
        bottom: 10%;
        width: 15rem;
        height: 9rem;
    }
    
    h1 {
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-top: 0;
        padding-bottom: 1rem;
        color: #F91212;
    }
`

const TermsAndCondition = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: radial-gradient(53.7% 45.18% at 50% 0%, rgba(249, 18, 18, 0.12) 0%, rgba(2, 13, 2, 0.00) 100%);
    width: 65%;

    & li {
        color: #FFF;
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 116.667% */
        text-transform: capitalize;
        list-style-type: decimal;
        padding-bottom: 1.5rem;

        .boldWhite {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-transform: capitalize;
        }
    }

    & h4 {
        color: #F91212;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
`;