
import { useEffect, useState } from 'react';


import { useQuery, useMutation, useLazyQuery } from '@apollo/client';


import styled, { withTheme } from 'styled-components';
// import Super7Logo from '../images/i_exch_loader.gif';
// import Super7Logo from '../images/i_exch_Logo-client_GIF.gif';

import { withAppContext } from 'src/store/initAppContext';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import config from 'src/config/config';
// import { ifProp, theme, withProp } from 'styled-tools';
// import graphqlApiUtil from 'src/util/graphqlApiUtil';
import { useParams } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty';
import util from 'src/util/util';
import { GET_PLAY_GAME } from 'src/graphql/game';
import EventNotFound from '../EventNotFound';
import { ONE_CLICK_RECYCLE } from 'src/graphql/system';
import { BalanceAnimation, LoadingWrap } from 'src/pages/loading';
import SEOContents from 'src/components/SEOContents';
import BTIseo from 'src/components/SEOComponents/BTIseo';
import { Helmet } from "react-helmet";

const _animationImg = '/static/mobile/brand/31/image/sportsbook-coins.gif'

const structuredData = (brandId) => {
	let domainURL = 'https://www.yolo247.net';
  
	if (brandId === 32) {
	  domainURL = 'https://www.yolo247.club';
	}
	else if (brandId === 33) {
	  domainURL = 'https://www.baaziadda.com';
	}
	else if (brandId === 34) {
	  domainURL = 'https://www.betdaily.club';
	}
	else if (brandId === 39) {
		domainURL = 'https://www.fomo7.com';
	  }

	const data = {
	  "@context": "https://schema.org",
	  "@type": "BreadcrumbList",
	  "itemListElement":[
		{
		  "@type": "ListItem",
		  "item": `${domainURL}`,
		  "name": "Homepage",
		  "position": 1
		},
		{
		  "@type": "ListItem",
		  "item": `${domainURL}/sportsbook/`,
		  "name": "Sportsbook",
		  "position": 2
		}
	  ],
	  "name": "Breadcrumbs"
	}
  
	return JSON.stringify(data)
}

const BTISports = (props: any) => {
	const { app: {
		brandId
	}, theme } = props
	const { showSEOfooterForYolo } = theme.config;


	const [isLoading, setLoading] = useState(true);
	const [iframeUrl, setIframeUrl] = useState('');
	const [noGame, setNoGame] = useState(false);
	const [triggerOneClickRecycle, { recycleLoading, recycleError, recycleData }]: any = useMutation(ONE_CLICK_RECYCLE)
	const { id } = useParams()
	
	const [getIframeData, { loading, error }] = useMutation(GET_PLAY_GAME, {
		variables: {
			input: {
				platformCode: "BTISPORTS",
				gameCode: `${id ? id : 'BTISPORTS'}`,
				homeURL: ""
			},
		}
	});

	useEffect(() => {
		getIframeData().then(async (response) => {
			const payload = await util.getValue(response, ['data', 'game', 'playgame'], {});
			setIframeUrl(payload.data.url)
			setLoading(true)
		}).catch((error) => {
			setNoGame(true)
		})
		return () => {
			cookieUtil.get(ECookieName.COOKIE_TOKEN) && triggerOneClickRecycle()
				.then((response) => {
					const result = util.getValue(response, ['data', 'game', 'recycleTransfer'], {});
					const { success, message, data } = result;
					if (success) {
						return
					}
				})
				.catch((error) => {
					console.error('Error in one click recycle')
				})

		}
	}, [props.platformDetails])

	if (loading) {
		return null
	}
	if (error) { return <p>Error...</p> }

	const hideSpinner = () => {
		setLoading(false)
	}

	return (
		<>
		<Helmet>
        <script type="application/ld+json">
          {
           structuredData(brandId)
          }

        </script>
      </Helmet>
		<SEOContents pageName={"sportsbook"}/>	
		{
			noGame ?
				<EventNotFound />
				:
				<GameContainer>
					{isLoading ? (
					<LoadingWrap> <img src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.loaderYOLO247}`} alt="logonew" /></LoadingWrap>
					) : null}
					<Main>
					{!isLoading && <BalanceAnimation imgSrc={_animationImg}  />}
						{
							<iframe
								src={iframeUrl}
								width="100%"
								height="700"
								onLoad={hideSpinner}
								frameBorder="0"
								marginHeight={0}
								marginWidth={0}
								title='CSBTI'
							/>
						}
					</Main>
				</GameContainer>
			}
			{showSEOfooterForYolo && <BTIseo/>}
		</>
	)
}

export default withTheme(withAppContext(BTISports));

export const GameContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const Main = styled.div`
	margin-top: 0rem;
	flex: 1;

	> iframe {
		height:98vh !important
	} 
`;