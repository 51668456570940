import { Suspense, lazy } from "react";
import FooterContainer from "src/components/footer/footer";
import { withAppContext } from "src/store/initAppContext";
import React, { PureComponent } from 'react';
import { theme, withProp } from 'styled-tools';
import styled, { withTheme } from 'styled-components'
import SEOContents from "src/components/SEOContents";

interface IProps {
    app: {
        brandId: number;
    };
}

const KycPolicy: React.SFC<IProps> = props => {
    const {
        app: { brandId },
    } = props;

    const Contents = brandId && brandId === 32 ? lazy(() => import("src/components/fine_print/kyc32")) : 
	brandId && brandId === 39 ? lazy(() => import("src/components/fine_print/kyc39")) :
	lazy(() => import("src/components/fine_print/kyc31"))

    return (

        <><ContentWrapper>
			<SEOContents pageName={"kycPolicy"}/>
            <Suspense fallback={<div>Loading ... </div>}>
                <Contents />
            </Suspense>
        </ContentWrapper><FooterContainer /></>

    );
};

export default withTheme(withAppContext(KycPolicy));


const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 1.5em 1.25em;
    color: white;
    text-transform: capitalize;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
        color: #f4a322;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		margin: 0.75em 0;
	}

	a {
		color: #e9d967;
		text-decoration: underline;
        cursor: pointer;
	}
`;