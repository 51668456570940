import { useEffect, Suspense, lazy } from "react";
import FooterContainer from "src/components/footer/footer";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";



function PrivacyPolicy(props: any) {

    const {
        app: { brandId },
    } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const Contents = brandId && brandId === 32 ? lazy(() => import("src/components/fine_print/privacy32")) : 
	brandId && brandId === 39 ? lazy(() => import("src/components/fine_print/privacy39")) : 
	lazy(() => import("src/components/fine_print/privacy31"))


    return (
        <>
            <ContentWrapper>
				<SEOContents pageName={"privacyPolicy"}/>
                <Suspense fallback={<div>Loading ... </div>}>
                    <Contents />
                </Suspense>
            </ContentWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(PrivacyPolicy));

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 5.5em 1.25em;
    text-transform: capitalize;

    h1{
color: #EEBC15;
    }

    p,
	h3,
	h4,
	h5,
	h6 {
		color: #fff;
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
        color: #fff;
		margin: 0.75em 0;
	}

	a {
		color: #EEBC15;
		text-decoration: underline;
	}

    span{
        font-weight: 700;
    }
`;