
import { useEffect, useState } from "react";
import {useMutation, useQuery} from "@apollo/client";
import styled, { withTheme } from "styled-components";
import { withAppContext } from "../../../store/initAppContext";
import cookieUtil from "../../../util/cookieUtil";
import { ECookieName } from "../../../util/utilModel";
import config from "../../../config/config";
import { ifProp, theme, withProp } from "styled-tools";
import _isEmpty from "lodash/isEmpty";
import util from "../../../util/util";
import EventNotFound from "../EventNotFound";
import { GET_BALANCE_INFO, GET_PLAY_GAME } from "../../../../src/graphql/game";
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
import { Routes, Route, useParams } from 'react-router-dom';
import { BalanceAnimation } from "src/pages/loading";
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
const _animationImg = '/static/mobile/brand/31/image/onexbet-coins.gif'

const structuredData = (brandId) => {
	let domainURL = 'https://www.yolo247.net';
  
	if (brandId === 32) {
	  domainURL = 'https://www.yolo247.club';
	}
	else if (brandId === 33) {
	  domainURL = 'https://www.baaziadda.com';
	}
	else if (brandId === 34) {
	  domainURL = 'https://www.betdaily.club';
	}
	else if (brandId === 39) {
		domainURL = 'https://www.fomo7.com';
	  }

	const data = {
	  "@context": "https://schema.org",
	  "@type": "BreadcrumbList",
	  "itemListElement":[
		{
		  "@type": "ListItem",
		  "item": `${domainURL}`,
		  "name": "Homepage",
		  "position": 1
		},
		{
		  "@type": "ListItem",
		  "item": `${domainURL}/premium-sportsbook/`,
		  "name": "Premium Sportsbook",
		  "position": 2
		}
	  ],
	  "name": "Breadcrumbs"
	}
  
	return JSON.stringify(data)
}



const PremiumSportsbook = (props: any) => {
  const {
    app: { brandId },
    theme,
  } = props;
  let { userId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState("");
  const [noGame, setNoGame] = useState(false);
  const [
    triggerOneClickRecycle,
	  { recycleLoading, recycleError, recycleData },
	]: any = useMutation(ONE_CLICK_RECYCLE);
	const { id } = useParams()
  const [getIframeData, { loading, error }] = useMutation(GET_PLAY_GAME, {
    variables: {
      input: {
        platformCode: "ONEXBET",
		gameCode: `${id ? id : 'ONEXBET'}`,
        homeURL: '',
      },
    },
  });
  const { data, startPolling, stopPolling } = useQuery(GET_BALANCE_INFO, {
        variables: {
            input: {
                platformCodes: ['ONEXBET'],
                target: util.getTargetString({ memberId: props.app.memberId, type: props.app.type }),
            },
        }
    });
	
	const balance = util.getValue(data, ['game', 'balanceInfo', '0', 'balance'], '-',);

	useEffect(() => {
		startPolling(7000); // poll interval
   
		return () => {
			stopPolling();
		};
      
	}, [balance])



	
  useEffect(() => {
    getIframeData()
      .then(async (response) => {
        const payload = await util.getValue(
          response,
          ["data", "game", "playgame"],
          {}
        );
        setIframeUrl(payload.data.url);
      })
      .catch((error) => {
        setNoGame(true);
      });
    return () => {
        cookieUtil.get(ECookieName.COOKIE_TOKEN) &&
          triggerOneClickRecycle()
            .then((response) => {
              const result = util.getValue(
                response,
                ["data", "game", "recycleTransfer"],
                {}
              );
              const { success, message, data } = result;
              if (success) {
                return;
              }
            })
            .catch((error) => {
              console.error('Error in one click recycle')
            });
      
    };
  }, [userId]);
  if (loading) {
    return null;
  }
  if (error) {
    return <p>Error...</p>;
  }
  const hideSpinner = () => {
    setLoading(false);
  };
  return (
    <>
    <Helmet>
        <script type="application/ld+json">
          {
           structuredData(brandId)
          }

        </script>
      </Helmet>
      {" "}
      <SEOContents pageName="premiumSportsbook"/>
      {noGame ? (
        <EventNotFound />
      ) : (
        <GameContainer>
        {isLoading ? (
                    <LoadingWrap> <img src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.loaderYOLO247}`} alt="logonew" /></LoadingWrap>
                ) : null}
          <BalanceBar>
            <CoinBalance>
              <span>Coin Balance : {balance}</span>
            </CoinBalance>
          </BalanceBar>
          <Main>
          {!isLoading  && <BalanceAnimation imgSrc={_animationImg}  style={{top:'51px'}}  />}
            {
              <iframe
                src={iframeUrl}
                width="100%"
                height="700"
                onLoad={hideSpinner}
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                title="CSVertual"
              />
            }
          </Main>
        </GameContainer>
      )}
    </>
  );
};
export default withTheme(withAppContext(PremiumSportsbook));
const LoadingWrap = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* background: ${theme('palette.secondary-background-color-linear-new')}; */
    & > img {
        width: 120px !important;
        /* height: 100px; */
    }
    height: 100vh;
`;
const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Main = styled.div`
  margin-top: 0rem;
  flex: 1;
  > iframe {
    height: 98vh!important
}
`;
const BalanceBar = styled.div`
    padding: 10px;
    /* background: ${theme('palette.backButtonColor')}; */
    background: ${theme('palette.secondary-background-color-linear-new1')};
    color: white;
    flex: 0 0 40px;
    text-align: right;
`;
const CoinBalance = styled.div`
    text-align: right;
`;