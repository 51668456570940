import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withTheme } from 'styled-components'
import { withAppContext } from 'src/store/initAppContext'

type Props = {
    app:any
}

const BetgamesSEO = (props: Props) => {

    const { app :{brandId}}=props;

    const domain = brandId === 31 || brandId === 32 ? 'YOLO247' : brandId === 33 ? 'Baazi Adda' : brandId === 39 ? 'FOMO7':  'Betdaily' ;
 
    
    return (
        <>
            <AccordionWrapper style={{width:'100%'}}>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label">
                        <h1>BET GAMES</h1></label>
                    <div className="accordion__content">
                    <h2>A Brief History of BetGames</h2> 
                        <p>BetGames has made a name for itself in the gaming industry. And no surprises; they have done it so fast. There are primarily two reasons for it. First, they have been a fast mover. Unlike some of its competitors, they have captured markets, especially emerging markets, with lightning-quick speeds.</p>
                        <p>Secondly, they want to reach out to customers who don't want to be bothered by the complex games. That's why the company creates easy-to-use games. BetGames has also done an excellent job of capturing an untapped market of people exploring online casino games.</p>
                        <p>Having set up on January 6, 2012, it was more than ten years ago that BetGames came up with their first-ever product, called the Lucky 7, which became a viral hit. In 2020, they launched two games: 6+ Poker and Polish Poker, besides adding two features: cashback and Jackpot.</p>
                        <p>A year later, they rebranded with a brand new office in Malta. The very same year, they also launched Andar Bahar. BetGames has headquarters in Vilnius, Lithuania. Also, it is one of the most reputable brands in the gaming industry, with world regulators having issued licenses. Here are some of them:</p>
                        <ul>
                            <li>Gambling Commission</li>
                            <li>Alcohol and Gambling Commission of Ontario</li>
                            <li>Western Cape Government</li>
                        </ul>
                        <p>Moreover, their excellence ensured that they got the top award three times. The award and winning years are as follows:</p>
                        <ul>
                            <li>SBC Award 2019</li>
                            <li>SBC Award 2020</li>
                            <li>SBC Award 2021 for Latin America</li>
                        </ul>
                        <p>These prestigious recognitions signify their successful growth in the online gaming industry.</p>

                        <h2>Why Play BetGames Exclusively On {domain}?</h2>
                        <p>Have you wondered why {domain} stands out from other gaming platforms? It is user-friendly. That is the primary reason it is a huge hit among people. Let us look at other reasons why {domain} has been a crowd favorite.</p>
                        <h3>User-Friendly:</h3>
                        <p>{domain} beats the rest when it comes to user-friendliness. Suppose a new player lands on the {domain} page; this is where the user-friendly feature of the website comes into play. The player can find the required game easily, allowing him to play and bet on it effortlessly.</p>
                        <h3>Navigate Like a King:</h3>
                        <p>The {domain} BetGames section, which is placed expertly, becomes visible to a player quickly. Moreover, every game is divided into subsections. Different game categories like Casino, Live Casino, and real-world sports like Cricket and Football exist.</p>
                        <h3>Prompt Payouts:</h3>
                        <p>You shouldn't think twice when playing on {domain}. The website accepts payment in numerous ways. Now, this could be Paytm, Google Pay, PhonePe, etc. Moreover, the money or your winnings are promptly disbursed.</p>
                        <h3>Efficient Customer Support:</h3>
                        <p>BetGames on {domain} is an unbeatable experience. Games are blissful, so you can chill and have a great gaming session. One big reason is the efficient customer support that is available to you.</p>
                        <h3>Masterful Graphics:</h3>
                        <p>{domain} BetGames is hassle-free, but do you know why it is so much fun? It's the graphics. Every game is depicted very well, with clean and clear projections of numbers. Check the small widget on the top-right-hand corner and figure out the rules with ease.</p>
                        <p>Playing on {domain} is always a fun and exciting adventure.</p>

                        <h2>How To Get Started on {domain} BetGames</h2>
                        <p>So, now you know almost everything about the gaming provider and how to play them on {domain}. So, you want to play? Sure, go ahead! But do you know how to register? Here's how.</p>
                        <ol>
                            <li>Visit the {domain} website or open the {domain} app</li>
                            <li>Select the Live Casino section under Casino on the homepage</li>
                            <li>Once you land on this page, you can see multiple subsections on your left, which are the game providers</li>
                            <li>On the left-hand corner, select the game provider BetGames</li>
                            <li>You will see the list of games</li>
                            <li>Place your bets to begin playing</li>
                        </ol>
                        <p>The process is simple, straightforward, and instant.</p>

                        <h2>BetGames On {domain} with a Wide Range of Thrilling Games At Your Disposal</h2>
                        <p>There are as many as ten offerings from the gaming provider on {domain}. In this section, we will take a look at all of them.</p>
                        <h3> Live Bet On Poker:</h3>
                        <p>One of the live dealer games offered by BetGames on {domain} is "Bet On Poker." The game opens with the live dealer sitting before the poker table. The whole idea of the game is to form the best card combination.</p>
                        <h3> Live Dice Duel:</h3>
                        <p>Another offering from the gaming provider on {domain} is "Live Dice Duel." The aim of the game is to predict the outcome of each dice roll correctly.</p>
                        <h3> Live Andar Bahar:</h3>
                        <p>Known as "Live 6+ Poker" on {domain}, this game is nothing but the widely popular Andar Bahar, which is very simple to play. It is very similar to the original Andar Bahar game.</p>
                        <h3> Live Lucky 5, Lucky 6, and Lucky 7:</h3>
                        <p>These are lottery-based games. In "Lucky 5," 36 balls are randomly drawn to the tube of the game machine. The player wins if five numbers chosen by the player are among these 36. For "Lucky 6," it is 60 balls, and for "Lucky 7," it is 42 balls.</p>
                        <h3> Live War of Bets:</h3>
                        <p>This live dealer game offered by BetGames on {domain} is as simple as it gets. There are two sides, and whoever has the higher card wins.</p>
                        <h3> Live Wheel of Fortune:</h3>
                        <p>As the name suggests, the players bet on possible outcomes on the wheel. This game is broadcast 24*7.</p>
                        <h3> Live Baccarat:</h3>
                        <p>This is yet another offering by BetGames on {domain}. The rules are almost the same as in Baccarat. The game is played between two sides: a player and a banker. The whole idea is to get a card value close to 9.</p>
                        <p>The games mentioned above make the gaming provider popular among bettors and provide an exciting and immersive gaming experience.</p>
                        <h2>FAQs about BetGames</h2>
                        <p>What are the games offered by BetGames?</p>
                        <p>Bet on Poker, Dice 6+ Poker, Blackjack, Lucky 5, Lucky 6, Lucky 7, War of Bets, Wheel, and Baccarat are the games offered by the BetGames provider.</p>
                        <p>Why is BetGames popular?</p>
                        <p>They are popular because they offer casino gaming enthusiasts an immersive gaming experience on {domain}.</p>
                    </div>
                </Accordion>
            </AccordionWrapper>
        </>
    )
}

export default withTheme(withAppContext(BetgamesSEO));