import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import { Divider, UnderLine } from './FomoWelcomeBonus';

function FomoLossBackBonus(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <InformationWrapper>
            <TitleText>
                <HeadingText>
                    <h1>You will get a bonus even if you lose</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>How exciting that sounds! That’s true on Fomo7. You are eligible for a Lossback bonus on your weekly losses.</p>
                    <p>Let’s make it simple, so that we can understand it better.</p>
                </HeadingText>
                <LazyLoadImage
                    src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoLossBackStepsDesk}`}
                    alt="jetxPromoStepThree"
                    effect="none"
                    placeholder={<PlaceholderImage />}
                />
                <ExampleText>
                    <p>Here is an example:</p>
                    <ul>
                        <li>Assume that you have lost Rs.10000.</li>
                        <li>You will qualify for a 5% cashback bonus on Fomo7.</li>
                        <li>The bonus amount is Rs.700.</li>
                        <li>You will receive the amount in your wallet the following Monday.</li>
                    </ul>
                    <p><b>Note: </b>We will consider the overall P&L of the week, not just the losses.</p>
                </ExampleText>
            </TitleText>
            <Divider></Divider>
            <LoyaltyWrapper>
                <HeadingText>
                    <h1>Fomo7 Lossback Bonus | Based on Loyalty Program</h1>
                </HeadingText>
                <UnderLine></UnderLine>
                <HeadingText className="joiningTxt">
                    <p>The entire bonus structure relies on the Loyalty Program.</p>
                </HeadingText>
                <JoiningWrapper>
                    <LazyLoadImage
                        src={`${config.imageV3Host}/static/cashsite/${theme.image.RefillPercentageFomoDesk}`}
                        alt="jetxPromoStepThree"
                        effect="none"
                        placeholder={<PlaceholderImage />}
                    />
                </JoiningWrapper>
            </LoyaltyWrapper>
            <Termsandconditions>
                <TermsandconditionsHeading>
                    <h1>Terms & Conditions</h1>
                    <h1>Bonus</h1>
                </TermsandconditionsHeading>
                <UnderLine></UnderLine>
                <TermsandconditionsBody>
                    <ol>
                        <li>Only one bonus is allowed per customer, family, address, shared computer, shared IP address, and any identical account details, including e-mail address, bank account details, credit card information and payment system account. Any misuse of this bonus offer will lead to an account being closed.</li>
                        <li>After calculating your weekly losses, you will receive the bonus amount automatically on Mondays.</li>
                        <li>This promotion is available on your weekly losses, and one’s overall P&L will decide the bonus on the platform.</li>
                        <li>The cashback bonus expires every 48 hours.</li>
                        <li>Fomo7 does not consider multiple accounts for this promotion. All players will be subject to duplicate checking. Fomo7 reserves the right to disqualify players if collusion or foul play occurs in the promotion.</li>
                        <li>Fomo7 reserves the right to amend, cancel, reclaim or refuse any promotion at its discretion.</li>
                        <li>You cannot withdraw the bonus amount, but the winnings from the bonus are withdrawable.</li>
                        <li>Fomo7 general terms & conditions apply.</li>
                    </ol>
                </TermsandconditionsBody>
            </Termsandconditions>
        </InformationWrapper>
    )
}



export default withTheme(withAppContext(FomoLossBackBonus));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 1.2em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoLossBackBgDesk")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0 4rem 0;
    text-align: center;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160.3%; /* 25.648px */
    }

    img {
        padding-top: 4rem;
        width: 90%;
    }
`;

const ExampleText = styled.div`
    text-align: left;
    padding-top: 3rem;

    p {
        text-align: left;
        color: white;
    }

    ul {
        padding: 1rem 1.5rem;
    }
    li {
        margin: 0;
        padding: 0;
        color: white;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%); */
    backdrop-filter: blur(6.849999904632568px);
    padding: 3rem;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.RefillBgFomoDesk")}), radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -999;
    margin: 0 -2.9rem;
    padding-bottom: 10rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        margin: 0;
        padding: 0.5em 0 0 0;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
        padding-top: 4rem;
        width: 73%;
    }

    .joiningTxt {
        padding-top: 2rem;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 85%;
    height: auto;
    margin: 0 7.5% 3rem 7.5%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(126deg, #061625 1%, #190c12 40%, #182419);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.fomoWelocomeTermsBefore")});
        width: 249px;
        height: 258px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -4%;
        bottom: -7%;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 1.5rem;

    h1 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;