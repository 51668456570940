import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import Footer from 'src/components/footer/footer';

function FomoWorldCupLossBackExch(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = brandId == 39 ? "FOMO7" : 'Baazi Adda';


    return (
        <>
            <InformationWrapper>
                <HeadWrapper>
                    <LeftWrapper>
                        <h1>Risk Free Bet!</h1>
                        <UnderLine></UnderLine>
                        <p>This World Cup, play like a cricket superstar only on {domain}!</p>
                        <p>We present the offer of the year for all crazy cricket fans! Lost the last match? No worries, {domain}  gives you a chance to play RISK FREE!</p>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcLossBckBonusTeamsImage}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </LeftWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcLossBckBonusPlayesImage}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                </HeadWrapper>
                <Divider></Divider>
                <LoyaltyWrapper>
                    <HeadingText className='titleText'>
                        <h1>Experience the thrill of daily bonus – 100% Loss Back</h1>
                    </HeadingText>
                    <UnderLine></UnderLine>
                </LoyaltyWrapper>
                <JoiningWrapper>
                    <JoiningContainer>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcLossBckBonusImgOne}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Deposit</h2>
                            <UnderLine></UnderLine>
                            <p>Deposit in your {domain} account. Keep it loaded and ready for action throughout the T20 WC</p>
                        </JoiningContent>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcLossBckBonusImgTwo}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Play</h2>
                            <UnderLine></UnderLine>
                            <p>Dive into the excitement of the World Cup matches by placing your bets on our cutting-edge exchange platform.</p>
                        </JoiningContent>
                        <JoiningContent>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.FomoWcLossBckBonusImgThree}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                            <h2>Claim Your Prize</h2>
                            <UnderLine></UnderLine>
                            <p>For cumulative losses of the day, we'll reward you with an 100% additional loss back bonus the very next day.</p>
                        </JoiningContent>
                    </JoiningContainer>
                </JoiningWrapper>
                <Termsandconditions>
                    <TermsandconditionsHeading>
                        <h1>Terms & Conditions</h1>
                    </TermsandconditionsHeading>
                    <UnderLine></UnderLine>
                    <TermsandconditionsBody>
                        <ol>
                            <li>The loss back bonus will be applied to ‘lost T20 WC EXCHANGE bets - for the day’, placed during the WC 2024.</li>
                            <li>The bonus amount for each qualifying bet will be capped at INR 50.</li>
                            <li>The bonus will be credited to your {domain} account the next day.</li>
                            <li>Bonus will be expired within 24-48 hours.</li>
                            <li>There is no limit to the number of bets you can place to earn the 100% Daily bonus, ‘for the day’.</li>
                            <li>The daily bonus can be used to place future bets on {domain} as this bonus cannot be withdrawn. Only winnings can be withdrawn.</li>
                            <li>This promotion is valid only for the T20 World Cup 2024.</li>
                            <li>{domain} reserves the right to disqualify any user found engaging in fraudulent activities.</li>
                            <li>{domain} General Terms & Conditions apply.</li>
                            <li>Valid from 2nd June 2024 to 29th June 2024!</li>
                        </ol>
                    </TermsandconditionsBody>
                </Termsandconditions>
            </InformationWrapper>
            <Footer />
        </>
    )
}



export default withTheme(withAppContext(FomoWorldCupLossBackExch));

export const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 0 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.WcLossBackExchFomoBgDesk")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
`;

const HeadWrapper = styled.div`
    display: flex;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcLossBckBonusBackgroud")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3rem 2rem 2rem 8rem;
    margin: 0 -3rem;
    gap: 5rem;
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 3rem 0 0 2rem;

    h1 {
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(156deg, #FED546 27.4%, #FE7C23 105.07%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }

    img {
        width: 60%;
        padding-top: 2rem;
    }
`;

const RightWrapper = styled.div`
    display: flex;
    width: 50%;
    text-align: right;

    img {
        width: 100%;
    }
`;

const LoyaltyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(37.24% 47.13% at 50% 0%, #051829 0%, rgba(1, 4, 6, 0.00) 100%);
    padding: 3rem;
    margin: 0 -2rem;

    .joiningTxt {
        padding-top: 1.5rem;
    }

    .titleText {
        padding: 1rem 0 1.2rem 0;
        h1 {
            margin: 0;
        }
    }
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 91.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.3%; /* 19.248px */
    }
`;

export const UnderLine = styled.div`
    height: 2px;
    width: 184px;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 80%);
`;

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#1182C1 0%, #00000000 50%);
`;

const JoiningWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: -999;
    margin: 0 -3rem;
`;

const JoiningContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    padding: 2rem 0 3rem 0;
`;

const JoiningContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 3rem 2rem 3rem;
    width: 25%;
    border-radius: 10px;
    border: 0.8px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(136.33% 139.78% at 0% 0%, rgba(254, 186, 59, 0.00) 63.5%, rgba(254, 186, 59, 0.20) 100%), radial-gradient(136.33% 139.78% at 0% 0%, rgba(255, 95, 31, 0.20) 0%, rgba(255, 95, 31, 0.00) 53.5%), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(12.5px);
    text-align: center;
    position: relative;

    p {
        margin-bottom: 0;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 145%; /* 23.2px */
    }

    img {
        width: 10rem;
        position: absolute;
        left: 30%;
        top: -20%;
    }

    h2 {
        color: #FEBA3B;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%; /* 29px */
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 0.5rem;
    }
`;

const Termsandconditions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 75%;
    height: auto;
    margin: 1rem 12.5% 3rem 12.5%;
    padding: 5rem 3rem;
    position: relative;
    background: linear-gradient(#020405, rgba(0, 0, 0, 0.935), #020405);
    background-clip: padding-box;
    border: 2px solid transparent;

    ::after {
        position: absolute;
        top: -2px; bottom: -2px;
        left: -2px; right: -2px;
        background: linear-gradient(90deg, #53C1FF, black, #53C1FF, black, #53C1FF);
        content: '';
        z-index: -1;
        border-radius: 30px;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcLossBckBonusTcBefore")});
        width: 6rem;
        height: 6rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -5%;
        bottom: 13%;
        z-index: -1;
    }
`;

const TermsandconditionsHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding-bottom: 2rem;

    h1 {
        margin: 0;
        padding: 0;
        background: linear-gradient(180deg, #FED546 18.06%, #FE7C23 121.53%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.25px;
    }
`;

const TermsandconditionsBody = styled.div`
    ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-top: 1rem;
    }
    li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    ::before {
        content: "";
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.FomoWcLossBckBonusTcAfter")});
        width: 9.39rem; /* 150.243px */
        height: 23.5rem; /* 376px */
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -10%;
        bottom: 35%;
        z-index: -1;
    }
`;